<template>
	<div class="card__content--wrap" v-if="application">
		<div class="card__content-header dashboard__content-header">
			<div class="content__title">
				<div class="content__title--element">
					<div class="content__title--text">
						{{ $t('dashboard.my_quota.title') }}
					</div>
					<input v-model="startAt" type="date" class="input-linck" name="startAt" />
					<span> - </span>
					<input v-model="endAt" type="date" class="input-linck" name="endAt" />
					<button class="reset" @click="reset">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="15" height="15" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 4a.995.995 0 00-.707.293l-2 2a.999.999 0 000 1.414L11.586 15l-7.293 7.293a.999.999 0 000 1.414l2 2a.999.999 0 001.414 0L15 18.414l7.293 7.293a.999.999 0 001.414 0l2-2a.999.999 0 000-1.414L18.414 15l7.293-7.293a.999.999 0 000-1.414l-2-2a.999.999 0 00-1.414 0L15 11.586 7.707 4.293A.996.996 0 007 4z"/></svg>
					</button>
				</div>
			</div>
		</div>
		<div class="card__content--body">
			<div class="row card__content--row">
				<div class="col-lg-8">
					<div class="dashboard__quota dashboard__quota--blue">
						<div class="row">
							<div class="col-lg-4 dashboard__col" v-if="application.total">
								<div class="dashboard__number dashboard__number--quota">
									<div class="dashboard__number--number">
										{{ application.total }}
									</div>
									<div class="dashboard__number--text">
										{{ $t('dashboard.my_quota.application.quota.text') }}
									</div>
								</div>
							</div>
							<!-- <div class="col-lg-3 dashboard__col">
                <div class="dashboard__number dashboard__number--quota">
                  <div class="dashboard__number--number">
                    {{ '' }}
                  </div>
                  <div class="dashboard__number--text">
                    {{ $t('dashboard.my_quota.application.permission.text') }}
                  </div>
                </div>
              </div> -->
							<div class="col-lg-4 dashboard__col" v-if="application.trade">
								<div class="dashboard__number dashboard__number--quota">
									<div class="dashboard__number--number">
										{{ application.trade }}
									</div>
									<div class="dashboard__number--text">
										{{ $t('dashboard.my_quota.application.trade.text') }}
									</div>
								</div>
							</div>

							<div class="col-lg-4 dashboard__col" v-if="application.remainder">
								<div class="dashboard__number dashboard__number--quota">
									<div class="dashboard__number--number">
										{{ application.remainder }}
									</div>
									<div class="dashboard__number--text">
										{{ $t('dashboard.my_quota.application.summ.text') }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-4">
					<div class="dashboard__quota dashboard__quota--blue">
						<div class="row">
							<div class="col-lg-12 dashboard__col" v-if="application.percentage">
								<div
									class="dashboard__number dashboard__number--quota"
									v-bind:class="{
										'dashboard__quota--green': application.percentage > 25,
										'dashboard__quota--error': application.percentage < 25,
									}"
								>
									<div class="dashboard__number--number">
										{{ application.percentage }}
									</div>
									<div class="dashboard__number--text">
										% {{ $t('dashboard.my_quota.permission') }}
									</div>
								</div>
							</div>
							<!-- <div class="col-lg-6 dashboard__col" v-if="application.percentage">
                <div
                  class="dashboard__number dashboard__number--quota"
                  v-bind:class="{
                    'dashboard__quota--green': application.percentage > 25,
                    'dashboard__quota--error': application.percentage < 25,
                  }"
                >
                  <div class="dashboard__number--number">
                    {{ application.percentage }}
                  </div>
                  <div class="dashboard__number--text">% {{ $t('dashboard.my_quota.trade') }}</div>
                </div>
              </div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { apiSecond } from '@/boot/axios'
import moment from 'moment'

export default {
	data() {
		return {
			application: null,
			startAt: null,
			endAt: null,
		}
	},
	watch: {
		startAt() {
			this.apiGetData()
		},
		endAt() {
			this.apiGetData()
		},
	},
	methods: {
		reset() {
			this.startAt = null
			this.endAt = null
			this.apiGetData()
		},
		getFormatDate(date) {
			return moment(date).format('YYYY-MM-DD')
		},
		setCurrentDate() {
			const currentDate = new Date()
			this.startAt = this.getFormatDate(new Date(currentDate.getFullYear(), 0, 1))
			this.endAt = this.getFormatDate(new Date(currentDate.getFullYear(), 11, 31))
		},
		apiGetData() {
			let url = `/v2/estimateJournal/common?`
			if (this.startAt) url += `startAt=${this.startAt}`
			if (this.endAt) url += `&endAt=${this.endAt}`
			apiSecond.get(url).then((response) => {
				if (response.data) {
					this.application = response.data
				}
			})
		},
	},
	beforeMount() {
		this.setCurrentDate()
		this.apiGetData()
	},
}
</script>

<style scoped>
.content__title--element {
	display: flex;
	align-items: center;
	gap: 15px;
}

.input-linck {
	border: none;
	padding: 0;
	background: transparent;
	width: 110px;
	cursor: pointer;
	outline: none;
}

.reset {
	display: block;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
}
</style>
