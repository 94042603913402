<template lang="">
	<FishingPermitTable
		:back="back"
		:data="data"
		:total="total"
		@reload="paginate"
		@paginate="paginate"
	/>
</template>

<script>
import { apiSecond } from '@/boot/axios'

import FishingPermitTable from '@/views/account/fishing-permit/FishingPermitTable.vue'

export default {
	components: { FishingPermitTable },
	props: {
		back: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			data: [],
			total: 0,
		}
	},
	mounted() {
		// this.fetchFishingPermits()
	},
	methods: {
		async fetchFishingPermits(page, per_page) {
			apiSecond
				.get('/v2/r13-moosr3-services?filter[nullable]=false', { params: { page, per_page } })
				.then((response) => {
					this.data = response.data.data || []
					this.total = response.data.meta.total
				})
				.catch((error) => {
					console.log(error)
				})
		},
		paginate(options) {
			this.fetchFishingPermits(options.page, options.itemsPerPage)
		},
	},
}
</script>

<style scoped></style>
