<template>
	<div class="card__content--wrap" v-if="application.length">
		<div class="card__content-header dashboard__content-header">
			<div class="content__title">
				<div class="content__title--element">
					<div class="content__title--text">
						{{ $t('dashboard.activity_log.title') }}
					</div>
				</div>
			</div>
		</div>

		<div class="card__content--body">
			<div class="activity-log__wrapper">
				<div class="row card__content--row">
					<div class="col-lg-12" v-for="(item, itemInd) in application" :key="itemInd">
						<div class="activity-log__item">
							<div class="activity-log__item--top">
								<div class="activity-log__item--time">
									{{ item.created_at | formatTime }}
									<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M2.5 6.875v1.25H10l-3.438 3.438.888.887L12.4 7.5 7.45 2.55l-.888.888L10 6.875H2.5z" fill="#0A091D"/></svg>
								</div>
								<div class="activity-log__item--date">
									{{ item.created_at | formatFullDate }}
									<span v-if="item.data && item.data.ip">(IP: {{ item.data.ip }})</span>
								</div>
							</div>
							<div class="activity-log__item--body">
								<div class="activity-log__item--text">
									{{ $t('messages.' + (item.message || '').trim()) }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

export default {
	data() {
		return {
			application: [],
		}
	},
	methods: {
		apiGetData() {
			api.get('actionlog/dashboard').then((response) => {
				if (response.data) {
					this.application = (response.data || []).slice(0, 20)
				}
			})
		},
	},
	beforeMount() {
		this.apiGetData()
	},
}
</script>
